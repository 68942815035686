import React from "react";
import SEO from "../components/atoms/seo";

import Product from "../components/organisms/product";

const LandingPageProduct = ({ content, ctaURL }) => {
  return (
    <div className="t-landing-page t-landing-page--product">
      <SEO title={content.seo.title} />
      <Product content={content.landingPage} ctaURL={ctaURL} />
    </div>
  );
};

export default LandingPageProduct;
